.react-query-devtools__button {
    svg {
        opacity: 0;
    }
    &:hover {
        svg {
            opacity: 1;
        }
    }
}
