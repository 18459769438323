@use "@edgetier/components/styles" as components-styles;
@use "@edgetier/client-components/styles" as client-components-styles;
@use "react-toggle/style";

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
#root {
    height: 100%;
}

#stacking-layers {
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}
